/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from "react";

import { BiArrowBack } from "react-icons/bi";

const Impressum = (props) => {
    useEffect(() => {}, []);

    return (
        <div className="">
            <div className="container-fluid content">
                <div className="container impressum textDark">
                    {" "}
                    <a href="../">
                        <BiArrowBack className="icon" height="2rem" width="2rem"></BiArrowBack>
                    </a>
                    <h1>Impressum</h1>
                    <h2>Angaben gemäß § 5 TMG</h2>
                    <p>
                        Sabocon GmbH
                        <br />
                        Alte Bogengasse 25
                        <br />
                        63303 Dreieich
                    </p>
                    <p>
                        Handelsregister: Hrb 3488
                        <br />
                        Registergericht: Handelsgericht Offenbach
                    </p>
                    <p>
                        <strong>Vertreten durch:</strong>
                        <br />
                        Marc Werner
                    </p>
                    <h2>Kontakt</h2>
                    <p>
                        Telefon: 0049-6103-9849-37
                        <br />
                        E-Mail: contact@sabocon.com
                    </p>
                    <h2>Umsatzsteuer-ID</h2>
                    <p>
                        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                        <br />
                        204691339
                    </p>
                    <h2>EU-Streitschlichtung</h2>
                    <p>
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
                        <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
                            https://ec.europa.eu/consumers/odr/
                        </a>
                        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
                    </p>
                    <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
                    <p>
                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Impressum;
