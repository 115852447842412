/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Intro = (props) => {
    useEffect(() => {}, []);

    return (
        <div className={`${props.class} introHeader container mt-5 slide-in-left`}>
            <div className="row">
                <div className="col">
                    <h1 className="mb-4">{props.title}</h1>
                    <h5 className="mb-5">{props.introText}</h5>
                </div>
            </div>
        </div>
    );
};

export default Intro;
