/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Header = (props) => {
    useEffect(() => {}, []);

    return (
        <div className="portfolioHeader container-fluid">
            <div className="portfolioHero">
                <img src={props.heroBG} alt="heroBG" />
            </div>
            {/* <div className="portfolioMain container ">
                <div className="imgWrapper container slide-in-top">
                    <img src={props.mainImg} alt="" />
                </div>
            </div> */}
        </div>
    );
};

export default Header;
