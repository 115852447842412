/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ScrollAnimation from "react-animate-on-scroll";

import XCCBG from "../../imgs/xcc.jpg";
import XCCMain from "../../imgs/xccHero.jpg";
import Cards from "../../imgs/cards.png";
import BgTexture from "../../imgs/bgTexture.png";
import Wireframe from "../../imgs/wireframe.png";
import Design from "../../imgs/design.png";
import Development from "../../imgs/development.png";
import Backend from "../../imgs/backend.png";
import XCCFin from "../../imgs/xccfinal.png";
import Cardsfin from "../../imgs/cardsfin.png";
import Backendfin from "../../imgs/backendfin.png";
import XCCFinal from "../../imgs/xccfin.png";

import Header from "../header.js";
import Intro from "../intro.js";
import XCCTEXT from "./text.js";
import TextImage from "../textimage";

import Animation from "../../animationsNew/data.json";
import AnimationDesign from "../../animationsDesign/data.json";
import Lottie from "lottie-react";

const XCC = (props) => {
    useEffect(() => {
        console.log(XCCTEXT.intro.text);
        console.log(Animation);
    }, []);

    return (
        <div className="">
            <Header heroBG={XCCBG} mainImg={XCCMain}></Header>
            <Intro
                title={ReactHtmlParser("XCC - <br> digital business card")}
                introText="Promote yourself - connect yourself"
            ></Intro>
            <div className="container-fluid content">
                <div className="container  textDark">
                    <div className="row">
                        <div className="col-lg-6 col-12 slide-in-left">
                            <p>XCC verbindet die haptische mit der digitalen Marketing Welt.</p>
                            <p>
                                Ihre individuell gestaltete Business Card ist mit einem NFC Chip ausgestattet, der bei
                                Berührung auf Ihre eigene Landingpage verlinkt
                            </p>
                            <p>
                                XCC ist nicht nur Visitenkarte. XCC kann auch als Informationstool für Produkte dienen.
                                So machen Sie ein Weinlabel zu einer Weinverkostung mit Informationsvideo,
                                Verzehrempfehlung, Location des Weingutes, Direktanruf, Direktbestellmöglichkeit, und
                                und und…
                            </p>
                        </div>
                        <div className="col-lg-6 col-12 mt-lg-0 mt-5 slide-in-right ">
                            <img src={Cards} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="container-fluid"
                style={{ backgroundImage: `url(${BgTexture})`, backgroundSize: "contain" }}
            >
                <div className="row">
                    <div className="col-lg-8 col-12 mt-md-0 mt-5 py-5 leftBox">
                        <div className="leftContent">
                            <h2 className="white">Ihre verknüpfte Landingpage</h2>
                            <p className="text-strong">Ihre eigene Landingpage auf unseren Servern gehostet.</p>
                            <p>
                                Vermitteln Sie wichtige Informationen über Ihr Business direkt über ihre Business Card.
                            </p>
                            <p>
                                Halten Sie die Karte an Ihr Smartphone und schon öffnet sich die Landingpage. Bestimmen
                                Sie den Inhalt selber. Unser System erlaubt Ihnen alle Freiheiten, ohne dabei zu komplex
                                zu werden.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 rightBox">
                        <ScrollAnimation animateIn="fadeIn">
                            <Lottie animationData={Animation} />
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="row gx-0">
                    <div className="col-md-4 col-12 mt-lg-0 mb-lg-0 mt-5 mb-3 imgBox">
                        <div className="textBox">
                            <h2>1. Konzeption</h2>
                            <p>Konzeption, Wireframing & Sitemapping</p>
                        </div>
                        <div className="overlayImg"></div>
                        <img src={Wireframe} alt="" />
                    </div>
                    <div className="col-md-4 col-12 mt-lg-0 mb-lg-0 mt-3 mb-3 imgBox">
                        <div className="textBox">
                            <h2>2. Design</h2>
                            <p>Design & Layout</p>
                        </div>
                        <div className="overlayImg"></div>
                        <img src={Design} alt="" />
                    </div>
                    <div className="col-md-4 col-12 mt-lg-0 mb-lg-0 mt-3 mb-5 imgBox">
                        <div className="textBox">
                            <h2>3. Development</h2>
                            <p>Development & Deployment</p>
                        </div>
                        <div className="overlayImg"></div>
                        <img src={Development} alt="" />
                    </div>
                </div>
            </div>
            <div className="container-fluid bgWhite py-5">
                {/* <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6 col-12">iohjdwoihdoihwdoi</div>
                        <div className="col-lg-6 col-12">
                            <img src={Backend} alt="" />
                        </div>
                    </div>
                </div> */}
                <div className="row my-5">
                    <div className="col-lg-6 col-12 mt-md-0 py-5 leftBox">
                        <div className="leftContent">
                            <h2 className="darkText">Content Managment</h2>
                            <p className="text-strong">XCC verwendet das beliebte Headless CMS Sanity.io</p>
                            <p>
                                Der User erhält Zugangsdaten zu seinem Dashboard, um seine auf der Business Card
                                verlinkte Landingpage komplett individuell anzupassen.
                            </p>
                            <p>
                                XCC kann beliebig gestaltet werden. Fügen Sie Buttons hinzu, verlinken Sie Dateien,
                                zeigen Sie Ihre Videos, teilen Sie Ihre Kontaktdaten etc.
                            </p>
                            {/* <ul>
                                <li>Design</li>
                                <li>Buttons</li>
                                <li>Contact Profile</li>
                                <li>Image Galery</li>
                                <li>Video Einbettung</li>
                                <li>Dateien Download</li>
                                <li>Externe Links</li>
                                <li>Maps</li>
                                <li>VCF Download</li>
                                <li>und mehr...</li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 rightBox py-5">
                        <img src={Backend} alt="" />
                    </div>
                </div>
            </div>
            <div
                className="container-fluid"
                style={{ backgroundImage: `url(${BgTexture})`, backgroundSize: "contain" }}
            >
                <div className="row">
                    <div className="col-lg-8 col-12 mt-md-0 mt-5 leftBox pt-5">
                        <div className="leftContent">
                            <h2 className="white">Use cases</h2>
                            <p className="text-strong">XCC passt zu jeder Art von Business.</p>
                            <p>
                                Sie können Speisekarten teilen, Online Reservierungen annehmen, exklusive Promos Codes
                                verteilen und vieles mehr.
                            </p>
                            <p>Sie können Ihre Landinpage nach Ihren eigenen Wünschen gestalten.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 rightBox">
                        <ScrollAnimation animateIn="fadeIn">
                            <Lottie animationData={AnimationDesign} />
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="row gx-0">
                    <div className="col-md-4 col-12 mt-lg-0 mb-lg-0 mt-5 mb-3 imgBox">
                        <div className="textBox">
                            <h2>NFC Business Card</h2>
                            <p>Gestaltung & Produktion der NFC Business Card</p>
                        </div>
                        <div className="overlayImg"></div>
                        <img src={Cardsfin} alt="" />
                    </div>
                    <div className="col-md-4 col-12 mt-lg-0 mb-lg-0 mt-3 mb-3 imgBox">
                        <div className="textBox">
                            <h2>Daten einpflegen</h2>
                            <p>Gestalten Sie Ihr XCC nach Ihren Wünschen</p>
                        </div>
                        <div className="overlayImg"></div>
                        <img src={Backendfin} alt="" />
                    </div>
                    <div className="col-md-4 col-12 mt-lg-0 mb-lg-0 mt-3 mb-5 imgBox">
                        <div className="textBox">
                            <h2>Promote yourself</h2>
                            <p>Ihre Business Card ist mit ihrer Landingpage verknüpft</p>
                        </div>
                        <div className="overlayImg"></div>
                        <img src={XCCFin} alt="" />
                    </div>
                </div>
            </div>
            <div className="container bottomRow">
                <div className="row">
                    <div className="col d-flex align-items-center flex-column justify-content-center my-5">
                        <img class="final" src={XCCFin}></img>
                        <a
                            href="https://sabocon.xcc-connect.com"
                            target="_blank"
                            className="d-block demo"
                            rel="noreferrer"
                        >
                            DEMO
                        </a>
                        <div className="subline mt-4">Optimiert für Smartphones</div>
                        <div className="subline mt-5">
                            <a href="./impressum" className="me-3">
                                Impressum
                            </a>
                            <a href="./privacy">Datenschutzerklärung</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default XCC;
