import logo from "./logo.svg";
import "./css/main.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Main from "./components/main";
import XCC from "./components/xcc/xcc";
import XCCDE from "./components/xcc/xcc_de";
import Impressum from "./components/xcc/impressum";
import ImpressumDE from "./components/xcc/impressum_de";
import Privacy from "./components/xcc/privacy";
import PrivacyDE from "./components/xcc/privacy_de";
import Baum from "./components/baum/baum";
import Cguest from "./components/cguest/cguest";
import Senator from "./components/senator/senator";

function App() {
    return (
        <div>
            <Router>
                <div className="App">
                    <Switch>
                        <Route path="/" exact component={XCCDE}></Route>
                        <Route path="/impressum" component={ImpressumDE}></Route>
                        <Route path="/privacy" component={PrivacyDE}></Route>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;
